import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Project = props => {
  console.log(props)
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  let imagesList = props.data.imagesData.edges
  let numberOfImages = imagesList.length

  const projectData = {
    ...props?.data.pageData.frontmatter,
    html: props?.data.pageData.html,
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImageNo, setselectedImageNo] = useState(0)

  return (
    <Layout>
      <Seo title={projectData.title}></Seo>

      {/* <Helmet>
        <title>Projects - {projectData.title}</title>
      </Helmet> */}

      <div className="px-32 py-8 sm:p-4">
        <h1 className="text-4xl sm:text-2xl text-center text-[#1F2626] my-8 font-bold dark:text-transparent dark:bg-darkHeading dark:bg-clip-text">
          {projectData.title}
        </h1>
        <div
          className=" w-full my-4 cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <img
            className="rounded-lg w-full shadow-project-card"
            src={projectData?.thumbnail?.childImageSharp?.fluid?.src}
          />
        </div>
        <div>
          <div className="text-[#1F2626] dark:text-[#BDEBEA] text-justify sm:text-sm my-2">
            {projectData?.technologies.split(",").length > 0 &&
              projectData?.technologies.split(",").map((tech, index) => (
                <p
                  key={index}
                  className="inline-block mr-4 sm:mr-2 p-2 px-4 sm:px-3 my-1 bg-[#5222D0] dark:bg-darkHeading text-[#DBFFFF] dark:text-[#1F2626] text-xs rounded-md"
                >
                  {tech}
                </p>
              ))}
          </div>
        </div>
        <div
          className="text-justify text-[#1F2626] dark:text-[#DBFFFF]"
          dangerouslySetInnerHTML={{ __html: projectData?.html }}
        ></div>
      </div>
      {isModalOpen && (
        <div className=" fixed top-0 left-0 h-[100vh] w-[100vw] bg-white/90 dark:bg-black/90 dark:text-white">
          <div
            className="absolute top-4 right-8 cursor-pointer text-xl"
            onClick={() => setIsModalOpen(false)}
          >
            X
          </div>
          <div
            className="text-xl cursor-pointer absolute top-[50%] right-12 sm:right-2"
            onClick={() => {
              setselectedImageNo(selectedImageNo + 1)
              if (selectedImageNo + 1 > numberOfImages - 1) {
                setselectedImageNo(0)
              }
            }}
          >
            &#10148;
          </div>
          <div
            className="text-xl cursor-pointer absolute top-[50%] left-8 sm:left-2 rotate-180 "
            onClick={() => {
              setselectedImageNo(selectedImageNo - 1)
              if (selectedImageNo - 1 < 0) {
                setselectedImageNo(numberOfImages - 1)
              }
            }}
          >
            &#10148;
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <div className="max-h-[80%] max-w-[80%] overflow-y-auto rounded-sm ">
              <img
                className="w-full"
                src={imagesList[selectedImageNo].node.childImageSharp.fluid.src}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Project

export const query = graphql`
  query MyQuery($id: String, $parent__name: String) {
    pageData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        desc
        images
        slug
        technologies
        title
        url
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              src
            }
          }
        }
      }
      html
    }
    imagesData: allFile(
      filter: {
        sourceInstanceName: { eq: "projectImages" }
        relativeDirectory: { eq: $parent__name }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              src
            }
          }
          relativeDirectory
        }
      }
    }
  }
`
